import styled, {css} from 'styled-components'
import {connect} from 'react-redux'
import { darken } from 'polished'

import breakpoints from '../../styles/breakpoints'
import borderRadius from '../../styles/borderRadius'
import {duration} from '../../styles/animation'
import space from '../../styles/space'
import getColor from '../../utils/getColor'

const Button = styled.button`
  border: none;
  cursor: pointer;

  transition: background-color ${duration.sm}s ease-in-out;
  padding: ${({padding}) => padding || `${space.md}px ${space.lg}px `};
  background-color: ${props => getColor(props, 'primaryButtonColor')};
  color: ${props => getColor(props, 'secondaryFontColor')};
  ${({br = true}) => br && borderRadius};

  :hover,
  :active,
  :focus {
    background-color: ${props => darken(0.14, getColor(props, 'primaryButtonColor'))};
  }

  /* If responsive */
  ${({bp, responsive, width}) => responsive ?  css`
    width: 100%;

    ${breakpoints[bp || 'md']} {
      width: ${width || 'auto'};
    }
  `: width && css`
    width: ${width};
  `}
`

export default connect(({theme}) => ({reduxTheme: theme}))(Button)