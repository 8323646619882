const wacs = [{
  title: 'Styling React Components',
  location: 'Seattle ReactJS',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'June, 2019'
},{
  title: 'Building a Website In React',
  location: 'Self Hosted',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2019'
},{
  title: 'Dallas Design and Code',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2019'
},{
  title: '1 Million Cups Dallas',
  role: 'organizer',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2017'
},{
  title: 'HackTX',
  role: 'mentor',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2016'
},{
  title: 'Tamu Hack',
  role: 'mentor',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2016'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
}, ]

export default wacs