const projects = [{
  title: 'Bison Studio',
  location: 'Seattle ReactJS',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'June, 2017 to present'
},{
  title: 'IBM',
  location: 'Self Hosted',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2016'
},{
  title: 'General Electric',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'CGI',
  role: 'organizer',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2014'
},{
  title: 'HackTX',
  role: 'mentor',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2016'
},{
  title: 'Tamu Hack',
  role: 'mentor',
  location: 'Meetup.com',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2016'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
},{
  title: 'How to User Your Terminal',
  role: 'organizer',
  location: 'IBM Internal',
  locationURL: 'https://twitter.com/seattlereactjs',
  date: 'July, 2015'
}, ]

export default projects