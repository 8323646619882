import React from 'react'
import styled, {css} from 'styled-components'
import {connect} from 'react-redux'

import borderRadius from '../styles/borderRadius'
import space from '../styles/space'
import getColor from '../utils/getColor'
import hexToRGB from '../utils/hexToRGB'
import {motion} from 'framer-motion'
import {colorTransition} from '../styles/animation'

import Row from './styled/Row'
import Col from './styled/Col'
import Button from './styled/Button'

const StyledCalloutTitle = styled(motion.h3)`
  color: ${({color, ...props}) => getColor(props, color || 'calloutTitleColor')};
`

const StyledCallout = styled(motion.div)`
  ${({br = true}) => br && borderRadius};
  ${colorTransition}

  max-width: 800px;
  position: relative;
  margin: auto;
  z-index: 1;
  padding: ${space.lg}px;

  background-color: ${({color, ...props}) => getColor(props, color || 'calloutColor')};
  color: ${({color, ...props}) => getColor(props, color || 'calloutFontColor')};

  ${({color, ...props}) => css`
    box-shadow: 0px ${space.xl*.4}px ${space.xl}px rgba(${hexToRGB(getColor(props, color || 'shadowColor'))}, 0.25);
  `};
`

const Callout = ({title, description, button, buttonLabel, ...rest}) => {
  return (
    <StyledCallout {...rest}>
      <Row>
        <Col xs={1} md={2/3}>
          {title && <StyledCalloutTitle>{title}</StyledCalloutTitle>}
          {description && <p>{description}</p>}
        </Col>
        <Col xs={1} md={1/3} align="right">
          {button && button}
          {!button && buttonLabel && (
            <Button responsive>
              {buttonLabel}
            </Button>
          )}
        </Col>
      </Row>
    </StyledCallout>
  )
}

export default connect(({theme}) => ({reduxTheme: theme}))(Callout)